<script lang="ts" setup>
import { LazyDefaultLayoutWithHorizontalNav, LazyDefaultLayoutWithVerticalNav } from '#components'
import { AppContentLayoutNav } from '@layouts/enums'
import { useLayoutConfigStore } from '@layouts/stores/config'
import { switchToVerticalNavOnLtOverlayNavBreakpoint } from '@layouts/utils'
import { isEmpty } from 'lodash-es'

const configStore = useLayoutConfigStore()
const route = useRoute()

const hasBreadcrumb = computed(() => !isEmpty(route.meta.breadcrumb))

// ℹ️ This will switch to vertical nav when define breakpoint is reached when in horizontal nav layout
// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint()

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()
</script>

<template>
  <Component
    v-bind="layoutAttrs"
    :is="configStore.appContentLayoutNav === AppContentLayoutNav.Vertical ? LazyDefaultLayoutWithVerticalNav : LazyDefaultLayoutWithHorizontalNav"
  >
    <Breadcrumbs v-if="hasBreadcrumb" />
    <PricingPlanDialog v-model:is-dialog-visible="configStore.isPricingPlanDialogVisible" />
    <slot />
  </Component>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-layout";
</style>
